<template>
    <div class="Employees" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Übersicht Ihrer" highlight="Mitarbeiter">
            <p class="Description">
                Verwalten Sie im unteren Bereich Ihr Personal oder fügen Sie rechts neue hinzu
            </p>
        </Headline>

        <Toolbar>
            <div style="align-items: flex-start">
                <div class="Search">
                    <Input placeholder="Fahrer suchen" keyName="query" @onKeyPress="handleSearch" />
                </div>
            </div>
            <div style="align-items: flex-start">
                <div class="ButtonDatePicker">
                    <button
                        class="Button is-plain is-small"
                        variant="plain"
                        size="small"
                        @click="handleGetDriversWithRevenue"
                    >
                        <Spinner v-if="isFetchingRevenueData"></Spinner>

                        <span v-if="!isFetchingRevenueData">
                            Fahrer mit Umsatz auswählen
                        </span>
                    </button>

                    <Datepicker
                        v-if="!isFetchingRevenueData"
                        :startDate="selectedFrom"
                        :endDate="selectedTo"
                        size="small"
                        @onChange="handleDateChange"
                        varinat="inline"
                        :hasQuickActions="false"
                    />
                </div>
                <Button size="small" @onClick="handleShowAddEmployeeModal">
                    <IconPlus slot="iconBefore" width="24px" height="24px"></IconPlus>
                    Mitarbeiter hinzufügen
                </Button>
            </div>
        </Toolbar>
        <span class="Hint">
            {{ filteredEmployees.filter(e => e.isVisible).length }} von {{ employees.length }}</span
        >
        <employees-table
            :employees="filteredEmployees"
            @onEmployeeClick="handleEditEmployee"
            @onEmployeeVisibilityChange="handleEmployeeVisibilityChange"
        />
        <Modal
            size="medium"
            :title="activeEmployee ? 'Mitarbeiter bearbeiten' : 'Mitarbeiter hinzufügen'"
            :show="modal.employee.isActive"
            :isLoading="isFetchingData"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveEmployee }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleModalClose"
        >
            <Section title="Benötigte Information">
                <div class="Input-Wrap">
                    <Input
                        label="Name"
                        placeholder="Name"
                        direction="vertical"
                        :error="nameError"
                        :value="activeEmployee && activeEmployee.name ? activeEmployee.name : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="driverPin"
                        label="Fahrer Pin"
                        placeholder="Fahrer Pin"
                        type="text"
                        direction="vertical"
                        :error="driverPinError"
                        :value="
                            activeEmployee && activeEmployee.driverPin
                                ? activeEmployee.driverPin
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <div>
                        <Input
                            keyName="driverNumber"
                            placeholder="Fahrer Nr."
                            direction="vertical"
                            type="number"
                            min="1"
                            max="9999"
                            :label="isNewEmployee ? 'Fahrer Nr.' : 'Fahrer Nr.'"
                            :isDisabled="!isNewEmployee"
                            :error="driverNumberError"
                            :value="
                                activeEmployee && activeEmployee.driverNumber
                                    ? activeEmployee.driverNumber
                                    : nextAvailableDriverNumber()[0]
                            "
                            @onKeyPress="handleKeypress"
                        />
                        <div v-if="isNewEmployee" class="Next-Driver-Number-Wrap">
                            <span>Verfügbare Fahrer Nummern</span>
                            <button
                                v-for="number in nextAvailableDriverNumber()"
                                :key="number"
                                class="Next-Driver-Number"
                                @click="handleKeypress({ driverNumber: number })"
                            >
                                {{ number }}
                            </button>
                        </div>
                    </div>
                </div>
            </Section>
            <Section title="Anschrift">
                <div class="Input-Wrap">
                    <Input
                        keyName="streetOne"
                        label="Adresszeile 1"
                        placeholder="Straße"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.streetOne
                                ? activeEmployee.streetOne
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="streetTwo"
                        label="Adresszeile 2"
                        placeholder="Adresszusatz"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.streetTwo
                                ? activeEmployee.streetTwo
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="zipCode"
                        label="PLZ"
                        placeholder="PLZ"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.zipCode ? activeEmployee.zipCode : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="city"
                        label="Stadt"
                        placeholder="Stadt"
                        direction="vertical"
                        :value="activeEmployee && activeEmployee.city ? activeEmployee.city : ''"
                        @onKeyPress="handleKeypress"
                    />
                </div>
            </Section>
            <Section title="Persönliche Information">
                <div class="Input-Wrap">
                    <Input
                        label="Geburtsdatum"
                        direction="vertical"
                        keyName="birthdate"
                        placeholder="dd.mm.yyyy"
                        :value="activeEmployeeBirthdate || ''"
                        @onKeyPress="handleKeypress"
                        type="date"
                    />
                    <Input
                        keyName="gender"
                        label="Geschlecht"
                        placeholder="Geschlecht"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.gender ? activeEmployee.gender : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        type="tel"
                        keyName="mobileNumber"
                        label="Telefon"
                        placeholder="Telefon"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.mobileNumber
                                ? activeEmployee.mobileNumber
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        type="email"
                        keyName="emailAddress"
                        label="Email"
                        placeholder="Email"
                        direction="vertical"
                        :value="activeEmployee && activeEmployee.email ? activeEmployee.email : ''"
                        @onKeyPress="handleKeypress"
                    />
                </div>
            </Section>
            <Section title="Fahrer Information">
                <div class="Input-Wrap">
                    <Input
                        keyName="driverLicenseNumber"
                        label="Führerschein Nr."
                        placeholder="Führerschein Nr."
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.drivingLicenceNumber
                                ? activeEmployee.drivingLicenceNumber
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="driverLicenseDueAt"
                        label="Führerschein Gültig bis"
                        placeholder="Gültig bis"
                        direction="vertical"
                        type="date"
                        :value="
                            activeEmployee && activeEmployee.driverLicenseDueAt
                                ? activeEmployee.driverLicenseDueAt
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />

                    <Input
                        keyName="passengerTransportCertificateNumber"
                        label="P-Schein Nr."
                        placeholder="P-Schein Nr."
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.passengerTransportCertificateNumber
                                ? activeEmployee.passengerTransportCertificateNumber
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="passengerTransportCertificateDueAt"
                        label="P-Schein Gültig bis"
                        placeholder="Gültig bis"
                        type="date"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.passengerTransportCertificateDueAt
                                ? activeEmployee.passengerTransportCertificateDueAt
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />

                    <Checkbox
                        @onCheck="handleDriverTypeChange"
                        :value="activeEmployee?.hasNightShiftPreference"
                        label="Nachtfahrer"
                        name="isNightDriver"
                        keyName="isNightDriver"
                    >
                        <p>
                            Nachtfahrer
                        </p>
                        <span class="Checkbox-Description">
                            Hiermit wird der Fahrer als Nachtfahrer markiert
                        </span>
                    </Checkbox>
                </div>
            </Section>
        </Modal>
    </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import Fuse from 'fuse.js';
import { endOfDay, endOfMonth, format, formatISO, startOfMonth } from 'date-fns';

import Button from '@/components/widgets/Button';
import Checkbox from '@/components/widgets/Checkbox';
import IconPlus from '@/components/icons/IconPlus';
import Headline from '@/components/Headline';
import Input from '@/components/widgets/Input';
import Modal from '@/components/widgets/Modal';
import Toolbar from '@/components/Toolbar';
import Section from '@/components/Section';
import EmployeesTable from './components/EmployeesTable.vue';
import Datepicker from '@/components/widgets/Datepicker.vue';
import Spinner from '@/components/widgets/Spinner.vue';

export default {
    name: 'Employees',
    components: {
        Spinner,
        Datepicker,
        Button,
        Headline,
        IconPlus,
        Input,
        Modal,
        Toolbar,
        Section,
        EmployeesTable,
        Checkbox,
    },
    data: () => ({
        format,
        isFetchingData: false,
        isFetchingRevenueData: false,
        employees: [],
        employeeWithRevenueButHidden: [],
        filteredEmployees: [],
        driverNumberError: '',
        driverPinError: '',
        nameError: '',
        currentIndex: null,
        activeEmployee: null,
        isNewEmployee: false,
        selectedFrom: startOfMonth(new Date()),
        selectedTo: endOfMonth(new Date()),
        modal: {
            delete: {
                isActive: false,
            },
            employee: {
                isActive: false,
            },
        },
    }),
    computed: {
        ...mapGetters(['user']),
        isSelfEmployed() {
            const legalForm = this.user.legalForm;
            return legalForm === 'Einzelunternehmer';
        },
        activeEmployeeBirthdate() {
            if (!this.activeEmployee) return '';
            if (!this.activeEmployee.birthdate) return '';
            return format(new Date(this.activeEmployee.birthdate), 'yyyy-MM-dd');
        },
    },
    methods: {
        ...mapActions(['getProfile']),
        handleDriverTypeChange(value) {
            this.activeEmployee = {
                ...this.activeEmployee,
                hasNightShiftPreference: !this.activeEmployee.hasNightShiftPreference,
            };
        },
        handleSearch({ query }) {
            if (query.length === 0) {
                this.filteredEmployees = this.employees;
                return;
            }
            const options = {
                minMatchCharLength: 2,
                threshold: Number.isNaN(Number(query)) ? 0.3 : 0.2,
                keys: ['name', 'driverNumber'],
            };
            const fuse = new Fuse(this.employees, options);
            const result = fuse.search(query);
            this.filteredEmployees = result.map(r => r.item).slice(0, 3);
        },
        handleModalClose() {
            this.activeEmployee = null;
            this.currentIndex = null;
            this.modal.delete.isActive = false;
            this.modal.employee.isActive = false;
        },
        async handleSaveEmployee() {
            const driverNumber = this.activeEmployee?.driverNumber || '';
            const name = this.activeEmployee?.name || '';
            const pin = this.activeEmployee?.driverPin || '';
            const driverNumberIsEmpty = driverNumber.length === 0;
            const nameIsEmpty = name.length === 0;
            const pinIsEmpty = pin.length === 0;
            const driverNumberIsTaken = this.employees.find(
                e =>
                    Number(e.driverNumber) === Number(driverNumber) &&
                    e.id !== this.activeEmployee?.id,
            );
            if (driverNumberIsTaken && this.isNewEmployee) {
                this.driverNumberError = 'Die Fahrer Nummer ist vergeben';
                this.$toasted.show('Die Fahrer Nummer ist vergeben', { type: 'error' });
                return;
            }
            if (pinIsEmpty) {
                this.driverPinError = 'Der Pin muss angegeben werden';
                this.$toasted.show('Der Pin muss angegeben werden', { type: 'error' });
                return;
            }
            // check if pin is a number
            if (isNaN(pin)) {
                this.driverPinError = 'Der Pin muss eine Nummer sein';
                this.$toasted.show('Der Pin muss eine Nummer sein', { type: 'error' });
                return;
            }
            this.driverPinError = '';
            if (nameIsEmpty) {
                this.nameError = 'Der Name muss angegeben werden';
                this.$toasted.show('Der Name muss angegeben werden', { type: 'error' });
                return;
            }
            this.nameError = '';
            this.driverNumberError = '';

            // get and update the driver in local storage
            const employeeLocations = JSON.parse(localStorage.getItem('employeeLocations')) || [];
            const driver = employeeLocations.find(e => e.uuid === this.activeEmployee.uuid);

            if (driver) {
                const index = employeeLocations.findIndex(e => e.uuid === this.activeEmployee.uuid);
                employeeLocations[index] = { ...employeeLocations[index], ...this.activeEmployee };
                localStorage.setItem('employeeLocations', JSON.stringify(employeeLocations));
            }

            if (this.isNewEmployee) {
                await this.handleAddEmployee({ ...this.activeEmployee });
            } else {
                await this.handleUpdateEmployee({ ...this.activeEmployee });
            }

            this.getProfile();
            this.handleLoadEmployees();
            this.handleModalClose();
        },
        async handleGetDriversWithRevenue() {
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                rangeStartAt: this.selectedFrom ? formatISO(this.selectedFrom) : null,
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };

            this.isFetchingRevenueData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });

                const {
                    employeesWithRevenue,
                    employeesWithoutRevenue,
                } = this.calculateDriverRevenue(result.data);
                console.log('employeesWithRevenue', employeesWithRevenue);
                console.log('employeesWithoutRevenue', employeesWithoutRevenue);
                const promises1 = employeesWithoutRevenue
                    .filter(e => !e.isSystemDriver)
                    .map(employee =>
                        this.handleUpdateEmployee(
                            { ...employee, isVisible: false },
                            { updateUI: false },
                        ),
                    );
                const promises2 = employeesWithRevenue
                    .filter(e => !e.isSystemDriver && !e.isVisible)
                    .map(employee =>
                        this.handleUpdateEmployee(
                            { ...employee, isVisible: true },
                            { updateUI: false },
                        ),
                    );

                if (promises1.length === 0 && promises2.length === 0) {
                    this.$toasted.show('Keine Änderungen erforderlich. Alle Fahrer sind aktuell.', {
                        type: 'info',
                    });
                    return;
                }

                await Promise.all([...promises1, ...promises2]);
                this.$toasted.show(
                    `Es wurden ${promises2.length} Fahrer mit Umsatz auf "Anzeigen" gesetzt.`,
                    {
                        type: 'success',
                    },
                );
                this.$toasted.show(
                    `Es wurden ${promises1.length} Fahrer ohne Umsatz auf "Verstecken" gesetzt.`,
                    {
                        type: 'success',
                    },
                );
            } catch (error) {
                console.error('Error fetching shifts:', error);
                this.$toasted.show('Fehler beim Laden der Fahrer mit Umsatz.', { type: 'error' });
            } finally {
                this.isFetchingRevenueData = false;
            }
        },
        calculateDriverRevenue(shifts) {
            const driverRevenueMap = {};
            // Build revenue data for each driver
            shifts.forEach(shift => {
                const driverId = shift.driverNumber;
                if (!driverRevenueMap[driverId]) {
                    driverRevenueMap[driverId] = {
                        driverNumber: driverId,
                        name: shift.driver.name,
                        totalRevenue: 0,
                    };
                }
                driverRevenueMap[driverId].totalRevenue += shift.finalTotalAmount;
            });

            // Find employees with revenue > 0
            const employeeWithRevenue = Object.values(driverRevenueMap).filter(
                driver => driver.totalRevenue > 0,
            );

            const employeeWithRevenueArr = this.employees.filter(e =>
                employeeWithRevenue.find(d => d.driverNumber === e.driverNumber),
            );

            const employeeWithoutRevenueArr = this.employees.filter(
                e => !employeeWithRevenue.find(d => d.driverNumber === e.driverNumber),
            );

            return {
                employeesWithRevenue: employeeWithRevenueArr,
                employeesWithoutRevenue: employeeWithoutRevenueArr,
            };
        },
        handleDateChange({ from, to }) {
            console.log(from, to);
            this.selectedFrom = from;
            this.selectedTo = to;
        },
        handleShowAddEmployeeModal() {
            this.isNewEmployee = true;
            this.modal.employee.isActive = true;
        },
        handleBirthdateChange({ from }) {
            this.activeEmployee = { ...this.activeEmployee, birthdate: format(from, 'yyyy-MM-dd') };
        },
        handleEmployeeVisibilityChange({ employee }) {
            this.handleUpdateEmployee({ ...employee, isVisible: !employee.isVisible });
            this.$toasted.show('Mitarbeiter wurde erfolgreich aktualisiert', {
                type: 'success',
            });
        },
        async handleEditEmployee({ id, index }) {
            const employee = this.employees.find(e => Number(e.driverNumber) === Number(id));
            this.currentIndex = index;
            this.activeEmployee = employee;
            this.modal.employee.isActive = true;
        },
        handleKeypress(employee) {
            this.activeEmployee = { ...this.activeEmployee, ...employee };
        },
        nextAvailableDriverNumber() {
            if (this.employees.filter(e => !e.isSystemDriver).length === 0) return 1;
            const driverNumbers = [
                ...this.employees.map(e => Number(e.driverNumber)).sort((a, b) => a - b),
            ];
            if (driverNumbers.length === 0) {
                return undefined; // No streak in an empty array.
            }
            let newDriverNumber = [];
            for (let i = 1; i < driverNumbers.length; i++) {
                const curr = driverNumbers[i];
                const candidate = Number(curr) + 1;
                if (driverNumbers.includes(candidate)) {
                    continue;
                } else {
                    newDriverNumber.push(candidate);
                }
            }

            return newDriverNumber.slice(0, 10);
        },
        async handleUpdateEmployee(employee, options = {}) {
            const { updateUI = true } = options;
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers/${employee.driverNumber}`;
                await axios.put(url, employee, {
                    withCredentials: true,
                });

                if (updateUI) {
                    const res = await axios.get(
                        `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
                        {
                            withCredentials: true,
                        },
                    );
                    const drivers = (res.data?.drivers || []).sort((a, b) =>
                        a?.value.localeCompare(b?.value),
                    );
                    localStorage.setItem('drivers', JSON.stringify(drivers));

                    this.$router.replace({ query: {} });
                }
                this.activeEmployee = null;
            } catch (error) {
                // get error data from server
                const { data } = error.response || {};
                const { message } = data.errors || {};
                if (message === 'driverPin must be of type string, got null') {
                    this.$toasted.show(`Fahrer PIN für ${employee.name} ist nicht gesetzt`, {
                        type: 'error',
                        duration: null,
                        action: {
                            text: 'Fahrer PIN setzen',
                            onClick: (e, toastObject) => {
                                this.activeEmployee = employee;
                                this.handleEditEmployee({ id: employee.driverNumber });
                                toastObject.goAway(0);
                            },
                        },
                    });
                    return;
                }
                this.$toasted.show('Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
        async handleAddEmployee(employee) {
            if (!employee.driverNumber) {
                const nextDriverNumber = this.nextAvailableDriverNumber()[0];
                employee.driverNumber = nextDriverNumber;
            }
            console.log({ employee });
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers`;
                const result = await axios.post(url, employee, {
                    withCredentials: true,
                });
                this.$toasted.show('Mitarbeiter wurde erfolgreich erstellt', {
                    type: 'success',
                });
            } catch (error) {
                this.$toasted.show('Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isNewEmployee = false;
                this.isFetchingData = false;
            }
        },
        async handleLoadEmployees() {
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });

                this.$emit('onFinishLoading');
                this.employees = result.data;
                this.filteredEmployees = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    async mounted() {
        await this.handleLoadEmployees();
        // if a uuid is passed in the url, we want to edit the employee
        const uuid = this.$route.query.uuid;
        if (uuid) {
            const employee = this.employees.find(e => e.uuid === uuid);
            if (employee) {
                this.handleEditEmployee({ id: employee.driverNumber });
            }
        }
    },
};
</script>

<style lang="scss">
.Employees {
    @extend %contentWrapper;
    @extend %page;

    .Input-Wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 20px;
        margin: 0px 0 30px;
        align-items: flex-end;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            grid-template-columns: 1fr;
        }
    }

    .Search {
        border: solid 1px var(--color-text-inactive);
        border-radius: 4px;
        width: 400px;
        margin: 0;

        .Input {
            padding: 0 12px;
            height: 36px;
        }
    }

    .Next-Driver-Number-Wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        margin-left: 12px;
        gap: 5px;

        > span {
            font-size: 12px;
            display: block;
            width: 100%;
        }

        .Next-Driver-Number {
            font-size: 12px;
            width: auto;
            color: var(--color-text);
            cursor: pointer;
            border: solid 1px var(--color-text-inactive);
            background-color: transparent;
            border-radius: 4px;
            padding: 4px;
            transition: all 0.2s ease-in-out;
            margin: 0;

            &:focus {
                outline: solid 1px var(--color-blue-dark);
            }

            &:hover {
                color: var(--color-text);
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .ButtonDatePicker {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Button {
            min-width: 205px;
            min-height: 38px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .Datepicker .Range,
        .Datepicker .To {
            border: none;
        }
    }
}
</style>
